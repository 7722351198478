import { NavbarBody, NavbarTitle, useOpenChat } from '@orus.eu/pharaoh'
import type { Permission } from '@orus.eu/right-access-management'
import { isSubsetOf } from '@orus.eu/sets'
import { useCallback, useState, type ReactNode } from 'react'
import { trpcReact } from '../../client'
import { useEmbeddingPartnerConfiguration } from '../../lib/embedding-partner'
import { useDisconnect } from '../../lib/hooks/use-disconnect'
import { getMutaSituation } from '../../lib/muta-util'
import { useSession } from '../../lib/session'
import { cguLink, confidentialityLink } from '../../lib/static-links'
import { usePermissions } from '../../lib/use-permissions'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'
import { AgentBlocker } from './no-orus-or-partner-agents'

export default function CustomerPage({
  children,
  requiredPermissions,
}: {
  children: ReactNode
  requiredPermissions: Permission[]
}): ReactNode {
  const session = useSession()
  const { permissions } = usePermissions()
  const disconnect = useDisconnect()
  const embeddingPartnerConfiguration = useEmbeddingPartnerConfiguration()
  const openChat = useOpenChat()

  // To prevent the error: "Rendered fewer hooks than expected. This may be caused by an accidental early return statement."
  const [isDisconnect, setIsDisconnect] = useState(false)

  const embeddingPartnerReturnUrl = embeddingPartnerConfiguration?.returnUrl

  const returnToEmbeddingPartner = useCallback(() => {
    if (embeddingPartnerReturnUrl) {
      document.location.href = embeddingPartnerReturnUrl
    }
  }, [embeddingPartnerReturnUrl])

  if (!session.user && !isDisconnect) return <InviteToLoginPage />
  if (['platform', 'partner'].includes(session.permissions.type) && session.user && 'email' in session.user)
    return <AgentBlocker email={session.user.email} />
  if (session.permissions?.type !== 'client') return <InviteToLoginPage />
  if (
    !isSubsetOf(
      new Set(requiredPermissions),
      new Set(session.permissions.rolesPermissions.flatMap((role) => role.permissions)),
    ) &&
    !isDisconnect
  ) {
    return <InviteToLoginPage />
  }

  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()
  const { isMuta, isMutaOnly } = getMutaSituation(contracts)
  const operatingZone = contracts[0]?.operatingZone ?? 'fr'

  return (
    <MenuPage
      menuTitle={<NavbarTitle variant="clientapp" />}
      menuBody={
        <NavbarBody
          variant="clientapp"
          disconnect={() => {
            setIsDisconnect(true)
            disconnect()
          }}
          displayHealthInsurance={isMuta}
          displayClaims={!isMutaOnly}
          handleHelp={openChat}
          confidentialityLink={confidentialityLink[operatingZone]}
          cguLink={cguLink[operatingZone]}
          embeddingPartner={
            embeddingPartnerConfiguration
              ? { name: embeddingPartnerConfiguration.name, returnToPartner: returnToEmbeddingPartner }
              : undefined
          }
          permissions={permissions}
        />
      }
      pageContent={children}
    />
  )
}
